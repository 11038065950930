﻿@mainColour_light: lighten( @mainColour,15%);
@mainColour_lighter: lighten( @mainColour,35%);
@mainColour_lightest: lighten( @mainColour,70%);
@mainColour_desaturated: desaturate( @mainColour_lighter,50%);

.main-body {
    .rc-handle { background-color: @mainColour !important; }

    table.main-table { border-bottom-color: @mainColour; background: #F3F2F2 !important;

        td.workitems { border-right-color: @mainColour; }
    }
}

.left-resize, .left-box { background-color: @mainColour_lightest; }

.defaultModal iframe { border-color: @mainColour; }

input[type="submit"] {
    &, &:hover { background: @default-button-color; }
}

a {
    &, &:hover { color: @default-button-color; }
}

.website-banner .top { background-color: @mainColour; }

.banner-drop-menu {
    &:hover .head-title { background-color: @mainColour_light; border-right-color: @mainColour_light; }

    .head-title { cursor: pointer; background-color: @mainColour; border-right-color: @mainColour; }

    .list { background-color: @mainColour; border-right-color: @mainColour;

        a:hover { background-color: @mainColour_light; }
    }

    div.colorPicker-picker { border-color: @mainColour_light; }
}

.work-item-details .fa { color: @mainColour; }


.work-items {
    .grid {

        tr {
            &.selected { background-color: @mainColour; }

            &:not(.selected):hover { background-color: @mainColour_desaturated; }

            td { border-bottom-color: @mainColour_lightest; }
        }
    }
}
.ui-resizable-e { background-color: @mainColour; }

.workitem-tags .tags.add-tag .item { background-color: @default-button-color; }

.website-banner a.std-button:hover, .website-banner .help-button:hover { background-color: @mainColour_light; }

.grid {
    td {
        a {
            &, &:hover { color: @default-button-color; }
        }
    }
}

/// EMPTY STYLES /////////////////////////////////////

.restart-temp-database { background: @mainColour !important; }

.menu-module .selected .item a { background: @mainColour; border-color: @mainColour; }

.menu-module .item a:hover { border-color: @mainColour; }

.set-environment-mode .buttons a { background-color: @mainColour_light; }
//////////////////////////////////////////////////////

/// FRONTEND MODAL STYLES ////////////////////////////

.top-bar { background: @mainColour; }

///////////////////////////////////////////////////////

.list-workplanitem {
    .grid {
        td { text-align: center; padding: 5px; font-size: 12px;
            a {
                &, &:hover { color: #0973e8; }
            }
        }
    }
}


.footer-links-bar {
    background-color: @mainColour;
}